<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="Logmonitoring" style="width:100vw">
  <div id="c3f0b35d">
    日志监控
  </div>
  <el-radio-group id="a8b77c53" v-model="rdg_a8b77c53">
    <el-radio-button id="ac0e23a3" label="错误日志">
    </el-radio-button>
    <el-radio-button id="e89a0e71" label="警告日志">
    </el-radio-button>
    <el-radio-button id="b27757a6" label="全部日志">
    </el-radio-button>
  </el-radio-group>
  <div id="a26c4f79">
    生产环境
  </div>
  <div id="e7d1df2d">
    <div id="a1f1c767">
      <div id="f1370b73">
        待确认事项的内容待确认事项的内容待确认事项的内容
      </div>
    </div>
    <img src="../assets/img-d3f94af0.png" id="d3f94af0"/>
    <div id="f7705ce2">
      <div id="affedf87">
        待确认事项的内容待确认事项的内容待确认事项的内容
      </div>
    </div>
    <img src="../assets/img-a6de8858.png" id="a6de8858"/>
    <div id="bdb24b69">
      <div id="a8a30f17">
        待确认事项的内容待确认事项的内容待确认事项的内容
      </div>
    </div>
    <img src="../assets/img-c371ed0d.png" id="c371ed0d"/>
    <div id="cef92d28">
      <div id="a65cae8e">
        待确认事项的内容待确认事项的内容待确认事项的内容
      </div>
    </div>
    <img src="../assets/img-a091c75f.png" id="a091c75f"/>
    <div id="a793cc2a">
      <div id="a1c0463c">
        待确认事项的内容待确认事项的内容待确认事项的内容
      </div>
    </div>
    <img src="../assets/img-d6a0c26e.png" id="d6a0c26e"/>
    <div id="c449fd11">
      <div id="a767137c">
        待确认事项的内容待确认事项的内容待确认事项的内容
      </div>
    </div>
  </div>
  <el-pagination id="a4c267dc">
  </el-pagination>
  <div id="a043adf7">
    测试环境
  </div>
  <div id="b26020ed">
    <div id="af488228">
      <div id="a1bb51fa">
        待确认事项的内容待确认事项的内容待确认事项的内容
      </div>
    </div>
    <img src="../assets/img-bd4474ec.png" id="bd4474ec"/>
    <div id="a92a6a2b">
      <div id="dcf9a256">
        待确认事项的内容待确认事项的内容待确认事项的内容
      </div>
    </div>
    <img src="../assets/img-a5b39b0f.png" id="a5b39b0f"/>
    <div id="a228e27d">
      <div id="a50f00f6">
        待确认事项的内容待确认事项的内容待确认事项的内容
      </div>
    </div>
    <img src="../assets/img-af89bdb1.png" id="af89bdb1"/>
    <div id="aff9e26a">
      <div id="a218ade0">
        待确认事项的内容待确认事项的内容待确认事项的内容
      </div>
    </div>
    <img src="../assets/img-a6ce9656.png" id="a6ce9656"/>
    <div id="af58f18a">
      <div id="ad6faba5">
        待确认事项的内容待确认事项的内容待确认事项的内容
      </div>
    </div>
    <img src="../assets/img-bc6d9e09.png" id="bc6d9e09"/>
    <div id="a717cf32">
      <div id="a1bd2b6b">
        待确认事项的内容待确认事项的内容待确认事项的内容
      </div>
    </div>
  </div>
  <el-pagination id="a59c9a77">
  </el-pagination>
  <div id="ad798f0c">
    开发环境
  </div>
  <div id="a7bf2068">
    <div id="aea272bc">
      <div id="b2378959">
        待确认事项的内容待确认事项的内容待确认事项的内容
      </div>
    </div>
    <img src="../assets/img-ad408160.png" id="ad408160"/>
    <div id="d3490c63">
      <div id="c0ae9d4a">
        待确认事项的内容待确认事项的内容待确认事项的内容
      </div>
    </div>
    <img src="../assets/img-a2bb4d0e.png" id="a2bb4d0e"/>
    <div id="a6d66030">
      <div id="ab02c212">
        待确认事项的内容待确认事项的内容待确认事项的内容
      </div>
    </div>
    <img src="../assets/img-ee410eac.png" id="ee410eac"/>
    <div id="a3af6ca1">
      <div id="aa5d3bf6">
        待确认事项的内容待确认事项的内容待确认事项的内容
      </div>
    </div>
    <img src="../assets/img-a0972463.png" id="a0972463"/>
    <div id="aa730fe6">
      <div id="ab9be10d">
        待确认事项的内容待确认事项的内容待确认事项的内容
      </div>
    </div>
    <img src="../assets/img-aa6757a2.png" id="aa6757a2"/>
    <div id="ff75dc5c">
      <div id="a9617b2b">
        待确认事项的内容待确认事项的内容待确认事项的内容
      </div>
    </div>
  </div>
  <el-pagination id="e67a48c2">
  </el-pagination>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
export default{
  data(){
    return{
    rdg_a8b77c53:''
      //在此注释下方添加自定义变量
    }
  },
  mounted(){
  },
  computed:{
  },
  watch:{
  },
  methods:{
    //在此注释下方添加自定义函数或事件
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#c3f0b35d{
  padding-top:100px;
}
#c3f0b35d{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#a8b77c53{
  width:916px;
  margin-top:60px;
  padding-left:365px;
}
#a8b77c53{
  width:auto;
  display:inline-block;
  vertical-align: middle;
}
#ac0e23a3{
  width:240px;
  margin-bottom:1px;
  display:inline-block;
  vertical-align: middle;
}
#ac0e23a3{
  background-color:rgb(20,22,32,1);
  border-radius:6px 6px 6px 6px;
}
#ac0e23a3 .el-radio-button__inner{
  width:240px;
  height:59px;
  line-height:59px;
  font-size:22px;
  font-family:PingFang SC;
  padding:0px;
}
#e89a0e71{
  width:238px;
  margin-left:100px;
  margin-top:0px;
  margin-bottom:0px;
  display:inline-block;
  vertical-align: middle;
}
#e89a0e71{
  background-color:rgb(255,255,255,1);
  border:2px solid #6f7484;
  border-radius:6px 6px 6px 6px;
}
#e89a0e71 .el-radio-button__inner{
  width:238px;
  height:59px;
  line-height:59px;
  font-size:22px;
  font-family:PingFang SC;
  padding:0px;
}
#b27757a6{
  width:238px;
  margin-left:100px;
  display:inline-block;
  vertical-align: middle;
}
#b27757a6{
  background-color:rgb(255,255,255,1);
  border:2px solid #6f7484;
  border-radius:6px 6px 6px 6px;
}
#b27757a6 .el-radio-button__inner{
  width:238px;
  height:60px;
  line-height:60px;
  font-size:22px;
  font-family:PingFang SC;
  padding:0px;
}
#a26c4f79{
  margin-top:60px;
}
#a26c4f79{
  text-align:right;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#e7d1df2d{
  width:1323px;
  position:relative;
  margin-top:30px;
  margin-left:163px;
}
#e7d1df2d{
  background-color:rgb(252,252,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:4px 4px 15px rgba(0,0,0,0.20);
}
#a1f1c767{
  width:727px;
  position:relative;
  padding-left:75px;
  padding-top:50px;
}
#f1370b73{
}
#f1370b73{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#d3f94af0{
  width:1216px;
  height:3px;
  margin-top:39px;
  padding-left:54px;
}
#d3f94af0{
  vertical-align:middle;
}
#f7705ce2{
  width:727px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
}
#affedf87{
}
#affedf87{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a6de8858{
  width:1216px;
  height:3px;
  margin-top:39px;
  padding-left:54px;
}
#a6de8858{
  vertical-align:middle;
}
#bdb24b69{
  width:727px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
}
#a8a30f17{
}
#a8a30f17{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#c371ed0d{
  width:1216px;
  height:3px;
  margin-top:39px;
  padding-left:54px;
}
#c371ed0d{
  vertical-align:middle;
}
#cef92d28{
  width:727px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
}
#a65cae8e{
}
#a65cae8e{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a091c75f{
  width:1216px;
  height:3px;
  margin-top:39px;
  padding-left:54px;
}
#a091c75f{
  vertical-align:middle;
}
#a793cc2a{
  width:727px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
}
#a1c0463c{
}
#a1c0463c{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#d6a0c26e{
  width:1216px;
  height:3px;
  margin-top:39px;
  padding-left:54px;
}
#d6a0c26e{
  vertical-align:middle;
}
#c449fd11{
  width:727px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
  padding-bottom:50px;
}
#a767137c{
}
#a767137c{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a4c267dc{
  width:302px;
  margin-top:30px;
  padding-left:677px;
}
#a043adf7{
  margin-top:60px;
}
#a043adf7{
  text-align:right;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#b26020ed{
  width:1323px;
  position:relative;
  margin-top:30px;
  margin-left:163px;
}
#b26020ed{
  background-color:rgb(252,252,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:4px 4px 15px rgba(0,0,0,0.20);
}
#af488228{
  width:727px;
  position:relative;
  padding-left:75px;
  padding-top:50px;
}
#a1bb51fa{
}
#a1bb51fa{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#bd4474ec{
  width:1216px;
  height:3px;
  margin-top:39px;
  padding-left:54px;
}
#bd4474ec{
  vertical-align:middle;
}
#a92a6a2b{
  width:727px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
}
#dcf9a256{
}
#dcf9a256{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a5b39b0f{
  width:1216px;
  height:3px;
  margin-top:39px;
  padding-left:54px;
}
#a5b39b0f{
  vertical-align:middle;
}
#a228e27d{
  width:727px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
}
#a50f00f6{
}
#a50f00f6{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#af89bdb1{
  width:1216px;
  height:3px;
  margin-top:39px;
  padding-left:54px;
}
#af89bdb1{
  vertical-align:middle;
}
#aff9e26a{
  width:727px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
}
#a218ade0{
}
#a218ade0{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a6ce9656{
  width:1216px;
  height:3px;
  margin-top:39px;
  padding-left:54px;
}
#a6ce9656{
  vertical-align:middle;
}
#af58f18a{
  width:727px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
}
#ad6faba5{
}
#ad6faba5{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#bc6d9e09{
  width:1216px;
  height:3px;
  margin-top:39px;
  padding-left:54px;
}
#bc6d9e09{
  vertical-align:middle;
}
#a717cf32{
  width:727px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
  padding-bottom:50px;
}
#a1bd2b6b{
}
#a1bd2b6b{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a59c9a77{
  width:302px;
  margin-top:30px;
  padding-left:677px;
}
#ad798f0c{
  margin-top:60px;
}
#ad798f0c{
  text-align:right;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#a7bf2068{
  width:1323px;
  position:relative;
  margin-top:30px;
  margin-left:163px;
}
#a7bf2068{
  background-color:rgb(252,252,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:4px 4px 15px rgba(0,0,0,0.20);
}
#aea272bc{
  width:727px;
  position:relative;
  padding-left:75px;
  padding-top:50px;
}
#b2378959{
}
#b2378959{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#ad408160{
  width:1216px;
  height:3px;
  margin-top:39px;
  padding-left:54px;
}
#ad408160{
  vertical-align:middle;
}
#d3490c63{
  width:727px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
}
#c0ae9d4a{
}
#c0ae9d4a{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a2bb4d0e{
  width:1216px;
  height:3px;
  margin-top:39px;
  padding-left:54px;
}
#a2bb4d0e{
  vertical-align:middle;
}
#a6d66030{
  width:727px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
}
#ab02c212{
}
#ab02c212{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#ee410eac{
  width:1216px;
  height:3px;
  margin-top:39px;
  padding-left:54px;
}
#ee410eac{
  vertical-align:middle;
}
#a3af6ca1{
  width:727px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
}
#aa5d3bf6{
}
#aa5d3bf6{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a0972463{
  width:1216px;
  height:3px;
  margin-top:39px;
  padding-left:54px;
}
#a0972463{
  vertical-align:middle;
}
#aa730fe6{
  width:727px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
}
#ab9be10d{
}
#ab9be10d{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#aa6757a2{
  width:1216px;
  height:3px;
  margin-top:39px;
  padding-left:54px;
}
#aa6757a2{
  vertical-align:middle;
}
#ff75dc5c{
  width:727px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
  padding-bottom:50px;
}
#a9617b2b{
}
#a9617b2b{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#e67a48c2{
  width:302px;
  margin-top:28px;
  padding-left:677px;
  padding-bottom:162px;
}
/*在此注释下方添加自定义样式*/
</style>
